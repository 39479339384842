import ParentModel from '@/models/parent/ParentModel.js';

export default class User extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.token = data.token;
         this.first_name = data.first_name;
         this.last_name = data.last_name;
         // this.email = data.email;
         this.phone = data.phone;
         this.password = data.password;
         this.password_confirmation = data.password_confirmation;
      } else {
         this.id = '';
         this.token = '';
         this.first_name = '';
         this.last_name = '';
         // this.email = '';
         this.phone = '';
         this.password = '';
         this.password_confirmation = '';
      }
   }

   get toDto() {
      const dto = {};
      dto.id = this.id;
      dto.token = this.token;
      dto.first_name = this.name;
      dto.last_name = this.email;
      // dto.email = this.phone;
      dto.phone = this.phone;
      dto.password = this.password;
      dto.password_confirmation = this.password_confirmation;
      return dto;
   }
}
