export default class ParentModel {
   constructor(data) {
      if (data != null) {
         this.id = data.id;
      } else {
         this.id = 0;
      }
   }

   get hasId() {
      return ((this.id != null) && (this.id !== '') && (this.id > 0));
   }
}
