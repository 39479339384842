import ParentService from '@/service/ParentService.js';

export default class RegistrationService extends ParentService {
   registerByInv(dto) {
      const url = `${super.baseUrl}registerUserByInvitation`;
      return super.customPost(url, dto);
   }

   validateInv(id, token) {
      const data = {
         id: id,
         token: token,
      };
      const url = `${super.baseUrl}user_invitation`;
      return super.customPost(url, data);
   }
}
